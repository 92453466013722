/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A solar powered computer, something like this: ", React.createElement(_components.a, {
    href: "http://juliansarokin.com/how-to-build-a-solar-powered-raspberry-pi/"
  }, "http://juliansarokin.com/how-to-build-a-solar-powered-raspberry-pi/"), " placed around a city with a wireless network interface setup to use ad-hoc mode such that anyone can walk within its wireless range, connect to the device with their mobile computer phone and exchange files with it."), "\n", React.createElement(_components.p, null, "Assuming you’d have to know of its existence beforehand, one method of doing so is to have a website that displays the locations and also the domain name of the machine so you can browse the files on the device just using a web browser."), "\n", React.createElement(_components.p, null, "There could be a simple upload form to send files from your device to it, with the correct security precautions in place to not allow executing any files."), "\n", React.createElement(_components.p, null, "If these are inside buildings there is no risk of physical damage by people or nature."), "\n", React.createElement(_components.p, null, "Then you’d have an awesome pseudo-sneakernet, filesharing network across a city."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
